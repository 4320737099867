import React from 'react';
import { Link } from 'react-router-dom';
import logo2 from './CAI-FOJ.png';
import banner from './banner-paybots.png';
import contentinspectors1 from './content_inspectors1.png';
import Carousel from './Carousel';



const HomePage = () => (
    <div className="App">
        <div className="section1">
            <header className="App-header">
                <img src={banner} className="App-banner" alt="a diverse group of artists, musicians, photographers, filmmakers and other creatives surrounded by their files"/>
                <h1>Content Inspector GPT</h1>
                <h2>Your C2PA Standards Analyst</h2>
                <p>Claim what's yours. Let others see the where you have done manual work and where you have used AI. C2PA is specifically designed to analyze
                    and authenticate digital content, ensuring its provenance and credibility in a world
                    increasingly challenged by misinformation. Content Inspector GPT is designed to help you create and read C2PA claims.
                </p>
            </header>
        </div>
        <div className="section2">
            <div>
                <h2>What Does Content Inspector GPT Do?</h2>
                <p ><Carousel />
                </p>
            </div>
        </div>
        <div className={"section4"}>
            <h2>What the Content Inspector Says</h2>
            <blockquote>"My purpose is to assist you create and understand C2PA manifests, focusing on their structure, assertions, certificates, and the customization of manifest templates."</blockquote>
        </div>
        <div className={"section5"}>
            <h2>How can I try the Content Inspector GPT?</h2>
            <p>You can visit us at our landing page on <a
                href={"https://chat.openai.com/g/g-YSGWbV7HP-content-inspector-gpt"}>OpenAI ChatGPT4
                (Subscription Necessary)</a> to try the Content Inspector GPT.
            </p>
        </div>
        <div className="section6">
            <img src={contentinspectors1} alt={"content inspectors"} className={"App-banner2"}/>
        </div>
        <footer className="App-footer">
            <h3>Powered by PayBots.ai</h3>
            <a
                className="App-link"
                href="https://friendsofjustin.knowbots.org"
                target="_blank"
                rel="noopener noreferrer"><img src={logo2} className="App-logo2"
                                               alt="Content Authenticity Initiative and Friends of Justin"/></a>
                <a className="App-link"
                href="https://friendsofjustin.knowbots.org"
                target="_blank"
                rel="noopener noreferrer">
                Brought to you by Friends of Justin
                </a>
        </footer>

        <Link to="/privacy">Go to Privacy Policy Page</Link>
    </div>

);

export default HomePage;