import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ci1 from './ci1.png';
import ci2 from './ci2.png';
import ci3 from './ci3.png';
import ci4 from './ci4.png';
import ci6 from './ci6.png';
import ci5 from './ci5.png';
import ci9 from './ci9.png';
import ci10 from './ci10.png';
import ci11 from './ci11.png';
import ci12 from './ci12.png';



const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 200,
        fade: true, // This enables the fade effect
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 20000,
    };

    return (
        <Slider {...settings}>
            <div>
                <img src={ci1} className="slick-slide" alt="Content Inspector 1" />
                <p className="caption"><strong>Interactive C2PA Verifier:</strong> Content Inspector GPT specializes in
                    inspecting uploaded manifests for credentials and provenance data in accordance with the
                    C2PA (Coalition for Content Provenance and Authenticity) standards.</p>
            </div>
            <div>
                <img src={ci2} className="slick-slide" alt="Content Inspector 2" />
                <p className="caption"><strong>Detailed Provenance Breakdown:</strong> The GPT dissects C2PA manifests and
                explains the significance of each element, including claims, assertions, and
                    credentials, to verify the integrity and provenance of media files.</p>
            </div>

            <div>
                <img src={ci3} className="slick-slide" alt="Content Inspector 3" />
                <p className="caption">
                    <strong>Guidance:</strong> Beyond analysis, it guides users in structuring digital
                    content to comply with C2PA standards and interactively interprets provenance data for
                    content verification purposes.
                </p>
            </div>
            <div>
                <img src={ci4} className="slick-slide" alt="Content Inspector 4" />
                <p className="caption">
                        <strong>Enhanced Trust in Digital Content:</strong> With Content Inspector GPT, be
                        assured of the authenticity and source of your digital content, building trust and
                        reliability.
                    </p>
            </div>
            <div>
                <img src={ci5} className="slick-slide" alt="Content Inspector 5" />
                <p><strong>Expert Insights:</strong> Benefit from enhanced insights on C2PA standards,
                    including the Rust implementation, for in-depth understanding and application in your
                    digital workflows.
                </p>
            </div>
            <div>
                <img src={ci6} className="slick-slide" alt="Content Inspector 6" />
                <p className="caption">
                    <strong>Tailored Solutions for Various Industries:</strong> Whether for media,
                    e-commerce, or digital archives, our GPT provides tailored solutions for diverse
                    industry needs.
                </p>
            </div>
            <div><img src={ci9} className="slick-slide" alt="Content Inspector 9" />
                <p className="caption">
                    <strong>Empower Your Content with Authenticity:</strong> In a digital landscape where authenticity is paramount, Content Inspector GPT at PayBots.ai stands as your expert companion. Ensure the integrity and trustworthiness of your digital content with our advanced AI solution.

                    Connect with us to explore how Content Inspector GPT can revolutionize your approach to digital content verification.
                    </p>
            </div>
            <div>
                <img src={ci10} className="slick-slide" alt="Content Inspector 10" />
                <p className="caption">
                    <strong>Something for Everyone!</strong> Whether you are a content creator, a content consumer, or a content curator, Content Inspector GPT is your go-to solution for verifying the authenticity of digital content. It is designed to help you create and read C2PA claims, ensuring the integrity and provenance of your digital content.
            </p></div>
            <div>
                <img src={ci11} className="slick-slide" alt="Content Inspector 11" />
                <p className="caption">
                    <strong>Who Uses C2PA?</strong> C2PA is a standard for content provenance and authenticity, developed by Adobe, Arm, BBC, Intel, Microsoft, and Truepic. It is designed to help users verify the authenticity of digital content, ensuring its provenance and credibility in a world increasingly challenged by misinformation. C2PA is especially valuable in legal and law enforcement contexts for verifying the authenticity of digital evidence.
                </p>
            </div>
            <div>
                <img src={ci12} className="slick-slide" alt="Content Inspector 12" />
                <p className="caption">
                    <strong>How Can I Get Started?</strong> Just visit the link below and sign into your paid ChatGPT4 account. Then, you can start using the Content Inspector GPT.
                </p>
            </div>
            {/* ...add more images as needed */}
        </Slider>
    );
};

export default Carousel;